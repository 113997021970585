import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { api } from '../constants';
export default function useGetCategoryProducts(id) {
  var _useState = useState(true),
      loading = _useState[0],
      setLoading = _useState[1];

  var _useState2 = useState([]),
      products = _useState2[0],
      setProducts = _useState2[1];

  useEffect(function () {
    if (id != undefined) axios.get("".concat(api.homeproductcategories, "/").concat(id)).then(function (response) {
      setProducts(response.data);
      setLoading(false);
    })["catch"](function (error) {
      setLoading(false);
      console.log(error.message);
    }).then(function () {
      setLoading(false);
    });
  }, [id]);
  return {
    products: products,
    loading: loading
  };
}